import { userAxios } from '@/services'

const membershipUserList = async (perPage) => {
    try {
        return await userAxios.get(`membership/users/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUserCreate = async (data) => {
    try {
        return await userAxios.post('membership/users/store', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUserUpdate = async (id, data) => {
    try {
        return await userAxios.put(`membership/users/update/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUserDelete = async (id) => {
    try {
        return await userAxios.delete(`membership/users/delete/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUserPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`membership/users/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUserFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`membership/users/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUserFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`membership/users/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUserBeneficiary = async (data) => {
    try {
        return await userAxios.post('membership/users/add-beneficiary', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    membershipUserList,
    membershipUserCreate,
    membershipUserUpdate,
    membershipUserDelete,
    membershipUserPagination,
    membershipUserFilter,
    membershipUserFilterPagination,
    membershipUserBeneficiary
}