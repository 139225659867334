<template>
  <div>
    <MembershipFilter v-if="$can('filter', 'module_modules.type_of_modules.memberships.users')" :loading="loading"
      @searchFilter="searchFilter" @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Memberships" iconTable="GitBranchIcon" />
      <div class="m-1">
        <MembershipEdit v-if="isEditMembershipSidebarActive" :is-edit-membership-sidebar-active.sync="isEditMembershipSidebarActive
          " :itemEdit="itemEdit" @editMembershipSuccess="editMembershipSuccess" />
        <MembershipAdd :is-add-new-membership-sidebar-active.sync="isAddNewMembershipSidebarActive
          " @createMembership="createMembership" />
        <BeneficiaryAdd v-if="isAddBeneficiarySidebarActive" :is-add-new-beneficiary-sidebar-active.sync="isAddBeneficiarySidebarActive
          " :isAddBeneficiarySidebarActive="isAddBeneficiarySidebarActive" :contributor="contributor"
          @addBeneficiary="addBeneficiaryRes" />
        <AppointmentPay v-if="conditionPayAppointment" :itemAppointment="itemToPay" @closeModalPay="closeModalPay"
          @paymentChecked="paymentChecked" @NotificationCheck="NotificationCheck" :isMembership="true"
          @updateItemAppoiment="handleUpdateAppoiment" />
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button v-if="
                $can(
                  'store',
                  'module_modules.type_of_modules.memberships.users'
                )
              " variant="primary" @click="isAddNewMembershipSidebarActive = true" :disabled="loading">
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Membership</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(show_details)="row">
          <b-form-checkbox v-model="row.detailsShowing" class="vs-checkbox-con" @change="toggleDetail(row)">
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <b-card>
            <b-table :items="row.item.beneficiaries" :fields="fieldsViewItem"></b-table>
            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(isPay)="data">
          <div class="text-nowrap">
            <b-badge
            class="badge-glow"
            :variant="data.item.active_membership.is_pay ? `success` : `danger`"
          >
          {{
          data.item.active_membership.is_pay ? $t("paid") : $t("notPaid")
        }}
        </b-badge>
            <!-- <span class="align-text-top text-capitalize">
          </span> -->
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Add beneficiary'" v-if="
              $can('update', 'module_modules.type_of_modules.memberships.users')
            " @click="addBeneficiary(data.item)">
            <feather-icon icon="UserPlusIcon" />
          </b-button>

          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Pay'" v-if="
              $can('update', 'module_modules.type_of_modules.memberships.users')
              && !data.item.active_membership.is_pay
            " @click="payAppointment(data.item)">
            <feather-icon icon="DollarSignIcon" />
          </b-button>

          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon"
            v-b-tooltip.hover.top="'Delete'" v-if="
              $can(
                'destroy',
                'module_modules.type_of_modules.memberships.users'
              )
            " @click="deleteMembership(data.item)">
            <feather-icon icon="TrashIcon" />
          </b-button>

        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import axiosCV from "@/services/admin/membership/users";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import { notificationCheck } from "@/mixins/NotificationSetup";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import MembershipEdit from "./UserMembershipEdit.vue";
import MembershipAdd from "./UserMembershipAdd.vue";
import BeneficiaryAdd from "./userMembershipBeneficiary.vue";
import AppointmentPay from "@/views/appointment/appointment-review/AppointmentPay.vue"
import MembershipFilter from "./UserMembershipFilter.vue";
import { ref } from "vue-demi";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    MembershipEdit,
    AppointmentPay,
    MembershipAdd,
    MembershipFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
    BFormCheckbox,
    BeneficiaryAdd,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        "show_details",
        "id",
        { key: "name", label: "Name", sortable: true },
        { key: "active_membership.name", label: "Membership", sortable: true },
        {
          key: "active_membership.pivot.end_date",
          label: "Expiration date",
          sortable: true,
        },
        { key: "isPay", label: "Payment status" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
        { key: "actions", label: "Actions" },
      ],
      fieldsViewItem: [
        { key: "id", label: "Id", sortable: true },
        { key: "full_name", label: "Name" },
        { key: "phone", label: "Phone" },
        { key: "date_birth", label: "DOB" },
      ],
      items: null,
      loading: false,
      isEditMembershipSidebarActive: false,
      isAddBeneficiarySidebarActive: false,
      isAddNewMembershipSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      membershipFilter: null,
      viewItem: null,
      contributor: null,
      itemToPay: {},
      conditionPayAppointment: false
    };
  },
  mounted() {
    this.getMembership();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getMembership();
      } else {
        this.searchFilter(this.membershipFilter);
      }
    },
  },
  methods: {
    payAppointment(item) {
      this.itemToPay = item;
      this.conditionPayAppointment = true;
    },
    handleUpdateAppoiment(value) {
      this.itemToPay = {};

      setTimeout(() => {
        this.itemToPay = value;
        this.conditionPayAppointment = true;
      }, 200);
    },
    closeModalPay() {
      this.itemToPay = {};
      this.conditionPayAppointment = false;
      this.pageChanged(this.currentPage.page);
    },
    paymentChecked(value) {
      this.pageChanged(this.currentPage.page);
    },
    getMembership() {
      axiosCV
        .membershipUserList(this.perPage)
        .then(({ registro: { data, total, current_page, ...res } }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    addBeneficiary(item) {
      this.isAddBeneficiarySidebarActive = true;
      this.contributor = ref(JSON.parse(JSON.stringify(item)));
    },
    editMembershipSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Membership updated successfully");
      } else {
        this.$refs.toast.error("Error updating type membership");
      }
      this.isEditMembershipSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createMembership({ registro }) {
      const { type, message } = registro;
      this.$refs.toast[type](message);
      this.isAddNewMembershipSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    addBeneficiaryRes({ registro }) {
      const { type, message } = registro;
      this.$refs.toast[type](message);
      this.isAddBeneficiarySidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteMembership(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosCV.membershipUserDelete(item.id).then((res) => {
            const { type, message } = notificationCheck(res);
            this.$refs.toast[type](message);
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV
          .membershipUserPagination(this.perPage, page)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosCV
          .membershipUserFilterPagination(
            this.perPage,
            page,
            this.membershipFilter
          )
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.membershipFilter = value;
        axiosCV
          .membershipUserFilter(this.perPage, value)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getMembership();
      }
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message);
    },
    filterPage(value) {
      this.filtro = value;
    },
    toggleDetail(value) {
      value.toggleDetails();
    }
  },
};
</script>

<style></style>
