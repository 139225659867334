import { userAxios } from "../index";

const patientList = async (perPage) => {
    try {
        return await userAxios.get(`patients/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientDetail = async (id) => {
    try {
        return await userAxios.get(`patients/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientCreate = async (data) => {
    try {
        return await userAxios.post('patients', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientCreateInsurance = async (data) => {
    try {
        return await userAxios.post('insurance-appointment/create-patient', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


const patientCreateOnly = async (data) => {
    try {
        return await userAxios.post('patients/create-only', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientUpdate = async (id, data) => {
    try {
        return await userAxios.put(`patients/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientDelete = async (id) => {
    try {
        return await userAxios.delete(`patients/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientPagination = async (perPage, paginate) => {
    try {
        return await userAxios.get(`patients/index/${perPage}?page=${paginate}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`patients/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientFilterPagination = async (perPage, paginate, data ) => {
    try {
        return await userAxios.post(`patients/filter/${perPage}?page=${paginate}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    patientList,
    patientDetail,
    patientCreate,
    patientCreateInsurance,
    patientCreateOnly,
    patientUpdate,
    patientDelete,
    patientPagination,
    patientFilter,
    patientFilterPagination
}